import { useEmblaCarousel } from 'embla-carousel/react'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import tw, { css } from 'twin.macro'
import quote from '../images/icon-quote-yellow.svg'
import { global } from '../styles/global'
import { StyleType } from '../utils/prop-types'
import Heading from './heading'
import Image from './image'
import Text from './text'

const TestimonialsAlternate = ({ testimonials, style }) => {
  const [viewportRef, embla] = useEmblaCarousel({ align: 'start' })
  const [selectedIndex, setSelectedIndex] = useState(0)
  const [scrollSnaps, setScrollSnaps] = useState([])

  const scrollTo = useCallback((index) => embla && embla.scrollTo(index), [embla])

  const onSelect = useCallback(() => {
    setSelectedIndex(embla.selectedScrollSnap())
  }, [embla, setSelectedIndex])

  useEffect(() => {
    if (!embla) return
    onSelect()
    setScrollSnaps(embla.scrollSnapList())
    embla.on('select', onSelect)
  }, [embla, onSelect])

  return (
    <>
      <div
        css={[
          global`layout.container`,
          css`
            ${tw`block overflow-visible`}
            &.is-draggable {
              ${tw`cursor-grab`}
            }
            &.is-dragging {
              ${tw`cursor-grabbing`}
            }
          `,
        ]}
        ref={viewportRef}
      >
        <div css={[tw`flex flex-row flex-nowrap`, style]}>
          {testimonials.map((t, i) => (
            <div key={i} css={tw`flex flex-col items-start flex-none w-full mr-8`}>
              <div css={tw`h-16 mb-8`}>
                <Image image={t.image} />
              </div>
              <Heading
                content={t.titleNode}
                headingType="h4"
                style={css`
                  blockquote {
                    &:before {
                      content: url(${quote});
                      ${tw`absolute h-4 -top-2 -left-8`}
                    }
                  }
                  ${tw`relative font-normal`}
                `}
              />
              <Text content={t.descriptionNode} style={tw`mt-6`} />
              <div css={tw`flex flex-row items-center mt-16 space-x-12`}>
                <Text
                  content={t.subtitleNode}
                  textType="body-base"
                  style={css`
                    ${tw`text-primary-500 text-opacity-80`}
                    strong {
                      ${tw`text-primary-500`}
                    }
                  `}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        css={tw`flex flex-row items-center justify-center w-full space-x-2
        lg:(justify-start mt-20)`}
      >
        {scrollSnaps.map((_, index) => (
          <button
            key={index}
            onClick={() => scrollTo(index)}
            css={[
              tw`w-24 h-0.5 bg-primary-500 opacity-30 transition-opacity duration-300 ease-in-out`,
              index === selectedIndex && tw`opacity-100`,
            ]}
          />
        ))}
      </div>
    </>
  )
}

TestimonialsAlternate.propTypes = {
  testimonials: PropTypes.arrayOf(PropTypes.object),
  style: StyleType,
}

export default TestimonialsAlternate
