import React from 'react'
import tw from 'twin.macro'
import { global } from '../styles/global'
import { HTMLType, ImageType, LinkType, StyleType } from '../utils/prop-types'
import Button from './button'
import Heading from './heading'
import Image from './image'
import Text from './text'

const FullImageBanner = ({
  image,
  imageMobile,
  subtitle,
  title,
  description,
  callToAction,
  style,
}) => (
  <div css={[tw`relative`, style]}>
    <div css={tw`lg:mx-container-lg-1/2 xl:mx-container-xl-1/2 xxl:mx-container-xxl-1/2`}>
      <Image image={image} imageMobile={imageMobile} style={tw`w-full h-186`} />
    </div>
    <div
      css={[
        global`layout.container`,
        global`layout.grid`,
        tw`absolute inset-0 items-start lg:(items-center) text-primary-500`,
      ]}
    >
      <div css={tw`flex flex-col items-start col-span-4 md:col-span-9`}>
        {subtitle && (
          <Heading
            content={subtitle}
            headingType="h5"
            style={tw`mb-6 mt-6 md:(mt-8) lg:(font-medium mb-12 mt-0)`}
          />
        )}
        <Heading content={title} headingType="h2" style={global`base.bold-to-medium`} />
        {description && <Text content={description} style={tw`mt-4`} />}
        {callToAction?.label && (
          <Button
            type="primary"
            size="sm"
            theme="navy-cyan"
            label={callToAction.label}
            link={callToAction.link}
            disabled={callToAction.disabled}
            style={tw`mt-6 sm:mt-10`}
          />
        )}
      </div>
    </div>
  </div>
)

FullImageBanner.propTypes = {
  image: ImageType,
  imageMobile: ImageType,
  subtitle: HTMLType,
  title: HTMLType,
  description: HTMLType,
  callToAction: LinkType,
  style: StyleType,
}

export default FullImageBanner
